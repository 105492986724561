<template>
  <div class="jh-container">
    <div class="jh-ui-header">
        <h1>상담사별 스킬관리</h1>
        <div class="is-right">
        </div>
    </div>
    <div class="jh-card">
      <div class="jh-card-header has-tab">
        <v-tabs slider-size="4" v-model="tab">
          <v-tab key="tab-1">스킬별 상담사설정</v-tab>
          <v-tab key="tab-2">상담사별 스킬설정</v-tab>
        </v-tabs>
      </div>
      <div class="jh-card-body">
        <v-tabs-items v-model="tab">
          <v-tab-item key="tab-1">
            <div class="tab-contnets">
              <div class="jh-search-form">
                <table>
                  <colgroup>
                    <col width="90px">
                    <col width="400px">
                    <col width="60px">
                    <col width="400px">
                    <col width="60px">
                    <col width="120px">
                    <col width="80px">
                    <col width="120px">
                    <col>
                  </colgroup>
                  <tr>
                    <th><label class="is-required">스킬(문의유형)</label></th>
                    <td>
                      <div class="jh-cols">
                        <v-select class="jh-form" :items="spstInqryTypItems1" item-text="CD_NM" item-value="CD" placeholder="선택" v-model="SPST_INQRY_TYP_CD1" @change="getSpstInqryTypLevel1(SPST_INQRY_TYP_CD1)"></v-select>
                        <v-select class="jh-form" :items="spstInqryTypItems2" item-text="CD_NM" item-value="CD" placeholder="선택" v-model="SPST_INQRY_TYP_CD2" @change="getSpstInqryTypLevel2(SPST_INQRY_TYP_CD2)"></v-select>
                        <v-select class="jh-form" :items="spstInqryTypItems3" item-text="CD_NM" item-value="CD" placeholder="선택" v-model="SPST_INQRY_TYP_CD3" ></v-select>
                      </div>
                    </td>
                    <th><label>소속</label></th>
                    <td>
                      <div class="jh-cols">
                        <v-select class="jh-form" :items="dropA" item-text="CD_NM" item-value="CD" placeholder="선택" v-model="USER_ATTR_A" @change="changeDept(USER_ATTR_A)"></v-select>
                        <v-select class="jh-form" :items="dropB" item-text="CD_NM" item-value="CD" placeholder="선택" v-model="USER_ATTR_B" @change="changeUser(USER_ATTR_A, USER_ATTR_B)"></v-select>
                        <v-select class="jh-form" :items="CNSL_COMBO" item-value="USER_ID" item-text="USER_NM" v-model="USER_ID"></v-select>
                        <!-- <v-select class="jh-form" :items="dropC" item-text="CD_NM" item-value="CD" placeholder="선택" v-model="USER_ATTR_C"></v-select> -->
                      </div>
                    </td>
                    <!--
                    <th><label>사번</label></th>
                    <td>
                      <v-text-field class="jh-form" name="name" id="id" v-model="USER_ID" @keyup.enter="searchUser"></v-text-field>
                    </td>
                    <th><label>상담사명</label></th>
                    <td>
                      <v-text-field class="jh-form" name="name" id="id" v-model="USER_NM" @keyup.enter="searchUser"></v-text-field>
                    </td>
                    -->
                    <td class="has-search"><v-btn class="jh-btn is-search" @click="searchUser">조회</v-btn></td>
                  </tr>
                </table>
              </div>
              <div class="jh-cols">
                <!--미할당스킬-->
                <div>
                  <div class="jh-ui-header">
                    <h2>미할당스킬</h2>
                  </div>
                  <v-data-table
                  class="jh-grid has-control"
                  height="560px"
                  :headers="gridDataHeaders"
                  :items="gridDataText"
                  :items-per-page="200"
                  :page.sync="page"
                  item-key="USER_ID"
                  show-select
                  fixed-header
                  hide-default-footer
                  no-data-text="검색된 결과가 없습니다."
                  v-model="selectedDivGridNoAllocList"
                  :item-class="isActiveRowLeft"
                  @page-count="pageCount = $event"
                  @click:row="getListSel"
                  ></v-data-table>
                  <div class="grid-paging text-center pt-2">
                    <v-pagination
                        v-model="page"
                        :length="pageCount"
                        :total-visible="totalVisible"
                        next-icon="svg-paging-next"
                        prev-icon="svg-paging-prev"
                    ></v-pagination>
                  </div>
                  <div class="jh-pager">
                    <span class="jh-counter is-left">총 <em>{{ TOT_COUNT }}</em> 건</span>
                  </div>
                </div>
                <!--//미할당스킬-->

                <div class="is-col-center">                                    
                  <v-btn class="jh-btn is-move is-md" v-if="this.mixin_set_btn(this.$options.name, 'btnAddArrow1')" @click="moveRight('tab1')"><i class="jh-icon-move-right"></i></v-btn>
                  <v-btn class="jh-btn is-move is-md" v-if="this.mixin_set_btn(this.$options.name, 'btnDelArrow1')" @click="moveLeftBtn('tab1')"><i class="jh-icon-move-left"></i></v-btn>
                </div>

                <!--할당스킬-->
                <div>
                  <div class="jh-ui-header">
                    <h2>할당스킬</h2>
                    <div class="is-right"><v-btn class="jh-btn is-main" @click="saveBtn('tab1')" v-if="this.mixin_set_btn(this.$options.name, 'btnSave1')">저장</v-btn></div>
                  </div>
                  <v-data-table
                  class="jh-grid has-control"
                  height="560px"
                  :headers="gridDataHeaders"
                  :items="gridDataText2"
                  :items-per-page="200"
                  item-key="USER_ID"
                  show-select
                  fixed-header
                  hide-default-footer
                  no-data-text="검색된 결과가 없습니다."
                  v-model="selectedDivGridAllocList"
                  :item-class="isActiveRowRight"
                  @click:row="getListSel2"
                  ></v-data-table>
                  <div class="jh-pager">
                    <span class="jh-counter is-left">총 <em>{{ gridDataText2.length }}</em> 건</span>
                  </div>
                </div>
                <!--//할당스킬-->
              </div>
            </div>
          </v-tab-item>

          <v-tab-item key="tab-2">
            <div class="tab-contnets">
              <div class="jh-search-form">
                <table>
                  <colgroup>
                    <col width="30px">
                    <col width="120px">
                    <col width="80px">
                    <col width="180px">
                    <col>
                  </colgroup>
                  <tr>
                    <th><label>사번</label></th>
                    <td>
                      <v-text-field class="jh-form" name="name" id="id" v-model="USER_ID_2" @keyup.enter="searchPopupUser"></v-text-field>
                    </td>
                    <th><label>상담사명</label></th>
                    <td>
                      <div class="jh-cols">
                        <v-text-field class="jh-form" name="name" v-model="USER_NM_2" @keyup.enter="searchPopupUser"></v-text-field>
                        <v-btn class="jh-btn has-icon-only is-fill" @click="searchPopupUser"><i class="jh-icon-search is-white"></i></v-btn>
                        <v-btn class="jh-btn has-icon-only" @click="initCus"><i class="jh-icon-refresh"></i></v-btn>
                      </div>
                    </td>
                    <td class="has-search"><v-btn class="jh-btn is-search" @click="searchSkillBtn">조회</v-btn></td>
                  </tr>
                </table>
              </div>
              <div class="jh-cols">
                <!--미할당스킬-->
                <div>
                  <div class="jh-ui-header">
                    <h2>미할당스킬</h2>
                  </div>
                  <v-data-table
                  class="jh-grid has-control"
                  height="560px"
                  :headers="gridDataHeaders2"
                  :items="gridDataText3"
                  :items-per-page="200"
                  :page.sync="page3"
                  item-key="TALK_INQRY_CD"
                  show-select
                  fixed-header
                  hide-default-footer
                  no-data-text="검색된 결과가 없습니다."
                  v-model="seletedAuthGroupNoAlloc"
                  :item-class="isActiveRowLeft2"
                  @page-count="pageCount3 = $event"
                  @click:row="getListSel"
                  ></v-data-table>
                  <div class="grid-paging text-center pt-2">
                    <v-pagination
                        v-model="page3"
                        :length="pageCount3"
                        :total-visible="totalVisible"
                        next-icon="svg-paging-next"
                        prev-icon="svg-paging-prev"
                    ></v-pagination>
                  </div>
                  <div class="jh-pager">
                    <span class="jh-counter is-left">총 <em>{{ TOT_COUNT3 }}</em> 건</span>
                  </div>
                </div>
                <!--//미할당스킬-->

                <div class="is-col-center">                                    
                  <v-btn class="jh-btn is-move is-md" v-if="this.mixin_set_btn(this.$options.name, 'btnAddArrow3')" @click="moveRight('tab2')"><i class="jh-icon-move-right"></i></v-btn>
                  <v-btn class="jh-btn is-move is-md" v-if="this.mixin_set_btn(this.$options.name, 'btnDelArrow3')" @click="moveLeftBtn('tab2')"><i class="jh-icon-move-left"></i></v-btn>
                </div>

                <!--할당스킬-->
                <div>
                  <div class="jh-ui-header">
                    <h2>할당스킬</h2>
                    <div class="is-right">                                  
                      <v-btn class="jh-btn is-move" v-if="this.mixin_set_btn(this.$options.name, 'btnDelArrow2')" @click="modifyBtn('up')"><i class="jh-icon-move-up"></i></v-btn>
                      <v-btn class="jh-btn is-move" v-if="this.mixin_set_btn(this.$options.name, 'btnAddArrow2')" @click="modifyBtn('down')"><i class="jh-icon-move-down"></i></v-btn>
                      <span class="jh-split"></span>
                      <v-btn class="jh-btn is-main" @click="saveBtn('tab2')" v-if="this.mixin_set_btn(this.$options.name, 'btnSave2')">저장</v-btn>
                    </div>
                  </div>
                  <v-data-table
                  class="jh-grid has-control"
                  height="560px"
                  :headers="gridDataHeaders3"
                  :items="gridDataText4"
                  :items-per-page="200"
                  item-key="TALK_INQRY_CD"
                  show-select
                  fixed-header
                  hide-default-footer
                  no-data-text="검색된 결과가 없습니다."
                  v-model="seletedAuthGroupAlloc"
                  :item-class="isActiveRowRight2"
                  @click:row="getListSel2"
                  ></v-data-table>
                  <div class="jh-pager">
                    <span class="jh-counter is-left">총 <em>{{ gridDataText4.length }}</em> 건</span>
                  </div>
                </div>
                <!--//할당스킬-->
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
    <!-- tabs -->

    <!-- 상담사조회 모달창 -->
    <v-dialog max-width="1000" v-model="DialogP101801">
      <dialog-P101801
      headerTitle="상담사조회"
      @hide="hideDialog('P101801')"
      :userInfo="userInfo"
      @input="userInfo = $event"
      @selectUser="[hideDialog('P101801'), setUser()]"
      ></dialog-P101801>
      <template v-slot:body></template>
    </v-dialog>
    <!-- 상담사조회 모달창 -->
  </div>
</template>

<script>
import {mixin} from "../../mixin/mixin";
import DialogP101801 from "./P101801.vue";

export default {
  name      : "MENU_E020607", //name은 'MENU_' + 파일명 조합
  mixins    : [mixin],
  components: {
    DialogP101801
  },
  data() {
    return {
      tab               : 'tab-1',
      DialogP101801     : false,
      userInfo          : {},
      menu1             : false,
      menu2             : false,
      radioGroup        : [
        {
          text: "상담사명",
        },
        {
          text: "최근접속일시",
        },
      ],
      radioGroupSelected: "상담사명",

      //resString : '',
      custItems         : [],
      listItems         : [
        {
          ATTR_CD    : "A",
          ATTR_DIV_CD: "",
        },
        {
          ATTR_CD    : "B",
          ATTR_DIV_CD: "",
        },
        {
          ATTR_CD    : "C",
          ATTR_DIV_CD: "",
        },
        {
          ATTR_CD    : "D",
          ATTR_DIV_CD: "",
        }
      ],
      dropA             : [{CD_NM: "전체", CD: ""}],
      dropB             : [{CD_NM: "전체", CD: ""}],
      dropC             : [{CD_NM: "전체", CD: ""}],
      dropD             : [{CD_NM: "전체", CD: ""}],
      CNSL_COMBO: [
        {
          USER_ID: "",
          USER_NM: "전체",
        },
      ],


      spstInqryTypItems1 : [{CD_NM: "선택", CD: ''}],
      spstInqryTypItems2 : [{CD_NM: "선택", CD: ''}],
      spstInqryTypItems3:  [{CD_NM: "전체", CD: ''}],

      gridDataHeaders : [
        {
          text    : "상담사명/사번",
          value   : "USER_NM_ID",
          align   : "center",
          sortable: true,
        },
        // {
        //   text    : "상담사명",
        //   value   : "USER_NM",
        //   align   : "center",
        //   sortable: true,
        // },
        // {
        //   text    : "상담사닉네임",
        //   value   : "USER_NICK",
        //   align   : "center",
        //   sortable: true,
        // },
        {text: "부서", value: "ATTR_DIV_NM_A", align: "center", sortable: true,},
        {text: "파트", value: "ATTR_DIV_NM_B", align: "center", sortable: true,},
        // {text: "소속C", value: "ATTR_DIV_NM_C", align: "center", sortable: true,},
        // {text: "소속D", value: "ATTR_DIV_NM_D", align: "center", sortable: true,},
      ],
      gridDataHeaders2: [
        {
          text    : "문의유형명",
          value   : "INQRY_TYP_NM",
          align   : "left",
          sortable: true,
        },
        {
          text    : "문의유형 타입",
          value   : "INQRY_TYPE",
          align   : "center",
          sortable: true,
        },
        {
          text    : "정렬순서",
          value   : "SORT_ORD",
          align   : "center",
          width   : "90px",
          sortable: true,
        },
      ],
      gridDataHeaders3: [
        {
          text    : "문의유형명",
          value   : "INQRY_TYP_NM",
          align   : "left",
          sortable: true,
        },
        {
          text    : "문의유형 타입",
          value   : "INQRY_TYPE",
          align   : "center",
          sortable: true,
        },
        {
          text    : "우선순위",
          value   : "SKILL_RANK",
          align   : "center",
          width   : "90px",
          sortable: true,
        },
      ],
      gridDataText    : [],
      gridDataText2   : [],
      gridDataText3   : [],
      gridDataText4   : [],

      asgnGridData : [],
      asgnGridData2: [],

      //paging
      page         : 1,
      page2        : 1,
      page3        : 1,
      page4        : 1,
      itemsPerPage : 15,
      pageCount    : 0,
      pageCount2   : 0,
      pageCount3   : 0,
      pageCount4   : 0,
      totalVisible : 10,
      TOT_COUNT    : 0,
      TOT_COUNT2   : 0,
      TOT_COUNT3   : 0,
      TOT_COUNT4   : 0,
      textareaRules: [(v) => v.length <= 200 || "Max 200 characters"],
      textareaValue: "200자 제한!",

      //v-model
      ASP_NEWCUST_KEY   : '001',
      ASP_NEWCUST_KEY_2 : '001',
      SPST_INQRY_TYP_CD1: '',
      SPST_INQRY_TYP_CD2: '',
      SPST_INQRY_TYP_CD3: '',
      USER_ID           : '',
      USER_NM           : '',
      USER_NICK         : '',
      USER_ID_2         : '',
      USER_NM_2         : '',
      USER_NICK_2       : '',
      USER_ATTR_A       : '',
      USER_ATTR_B       : '',
      USER_ATTR_C       : '',
      USER_ATTR_D       : '',
      CNSL_ID           : '',


      //그리드 v-model
      selectedDivGridNoAllocList: [],
      selectedDivGridAllocList  : [],
      seletedAuthGroupNoAlloc   : [],
      seletedAuthGroupAlloc     : [],
      detailData                : [],
      detailData2                : [],

      //msg
      msgList:
          {
            noAspCustKey : "회사구분란에서 회사를 선택후 이용해주십시오",
            noAlloSelected : "미할당 상담사가 선택되지 않았습니다.",
            alloSelected   : "할당 상담사가 선택되지 않았습니다.",
            requiredInqry  : "스킬(문의유형1)은(는) 필수입력입니다.",
            requiredUser : "상담사 ID 은(는) 필수입력입니다.",
            skillNotSeleted: "선택된 스킬이 없습니다.",
            cantUp         : "선택된 스킬의 우선순위를 올릴 수 없습니다.",
            chkUpDown      : {"up": "선택한 스킬의 우선순위를 올리시겠습니까?", "down": "선택한 스킬의 우선순위를 내리시겠습니까?"},
            noGridList     : "데이터를 먼저 조회해주세요",
            saveMsg        : "저장하시겠습니까?",
            savedMsg       : "정상적으로 등록되었습니다.",
            moveLeft       : {"tab1": "선택한 상담사를 미할당 처리 하시겠습니까?", "tab2": "선택한 스킬을 미할당 처리 하시겠습니까?"}
          },

    };
  },
  methods: {
    getListSel(item){
      // 선택한 아이템 저장
      this.detailData = JSON.parse(JSON.stringify(item));
    },

    getListSel2(item){
      // 선택한 아이템 저장
      this.detailData2 = JSON.parse(JSON.stringify(item));
    },

    isActiveRowLeft(item){
      return item.USER_ID== this.detailData.USER_ID? 'is-active':'';
    },

    isActiveRowRight(item){
      return item.USER_ID== this.detailData2.USER_ID? 'is-active':'';
    },


    isActiveRowLeft2(item){
      return item.TALK_INQRY_CD== this.detailData.TALK_INQRY_CD? 'is-active':'';
    },

    isActiveRowRight2(item){
      return item.TALK_INQRY_CD== this.detailData2.TALK_INQRY_CD? 'is-active':'';
    },

    searchPopupUser(){
      this.btnUser()
      this.showDialog('P101801')
    },

    showDialog(menu) {
      this[`Dialog${menu}`] = true;
    },

    hideDialog(menu) {
      this[`Dialog${menu}`] = false;
    },
    submitDialog(type) {
      console.log("완료");
      this[`dialog${type}`] = false;
    },

    async changeDept(attrA){
        this.dropB = await this.mixin_get_attr_by_attr('', attrA);
        this.CNSL_COMBO = [{USER_ID: "",USER_NM: "전체",},];
        this.USER_ATTR_B = '';
        this.USER_ID = '';
    },
    async changeUser(attrA, attrB){
        this.CNSL_COMBO = await this.mixin_get_user_by_attr(attrA, attrB);
        this.USER_ID = '';
    },
    //스킬 가져오기
    async getSpstInqryTypList() {
      this.spstInqryTypItems1 = [{CD_NM: "선택", CD: ''}];

      let requestData = {
        headers : {},
        sendData: {}
      };

      // header 세팅
      requestData.headers["URL"] = "/api/chat/setting/inqry-ty-manage/list";
      requestData.headers["METHOD"] = "list";
      requestData.headers["SERVICE"] = 'chat.setting.inqry-ty-manage';
      requestData.headers["TYPE"] = "BIZ_SERVICE";

      // sendData 세팅
      requestData.sendData["ASP_NEWCUST_KEY"] = '001';
      requestData.sendData["SPST_INQRY_TYP_CD"] ='*';
      requestData.sendData["USE_YN"] = "Y"

      let spstInqryTypResponse = await this.common_postCall_noPB(requestData);

      this.spstInqryTypCallback(spstInqryTypResponse);
    },
    spstInqryTypCallback(response) {
      this.spstInqryTypItems1.push(...response.DATA);
    },

    //문의유형 가져오기, 추후 공통코드 활용할 것
    async getSpstInqryTypLevel1(SPST_INQRY_TYP_CD) {
      this.spstInqryTypItems2 = [{CD_NM: "선택", CD: ''}];
      this.SPST_INQRY_TYP_CD2 = '';
      this.spstInqryTypItems3 = [{CD_NM: "전체", CD: ''}];
      this.SPST_INQRY_TYP_CD3 = '';

      if(SPST_INQRY_TYP_CD == ''){
        return false;
      }

      let requestData = {
        headers : {},
        sendData: {}
      };

      // header 세팅
      requestData.headers["URL"] = "/api/chat/setting/inqry-ty-manage/list";
      requestData.headers["METHOD"] = "list";
      requestData.headers["SERVICE"] = 'chat.setting.inqry-ty-manage';
      requestData.headers["TYPE"] = "BIZ_SERVICE";

      // sendData 세팅
      requestData.sendData["ASP_NEWCUST_KEY"] = '001';
      requestData.sendData["SPST_INQRY_TYP_CD"] = SPST_INQRY_TYP_CD;
      requestData.sendData["USE_YN"] = "Y"

      let response = await this.common_postCall_noPB(requestData);
      this.spstInqryTypLevel1Callback(response);
    
    },
    spstInqryTypLevel1Callback(response) {
      this.spstInqryTypItems2.push(...response.DATA);
    },

    async getSpstInqryTypLevel2(SPST_INQRY_TYP_CD) {
      this.spstInqryTypItems3 = [{CD_NM: "전체", CD: ''}];
      this.SPST_INQRY_TYP_CD3 = '';

      if(SPST_INQRY_TYP_CD == ''){
        return false;
      }

      let requestData = {
        headers : {},
        sendData: {}
      };

      // header 세팅
      requestData.headers["URL"] = "/api/chat/setting/inqry-ty-manage/list";
      requestData.headers["METHOD"] = "list";
      requestData.headers["SERVICE"] = 'chat.setting.inqry-ty-manage';
      requestData.headers["TYPE"] = "BIZ_SERVICE";

      // sendData 세팅
      requestData.sendData["ASP_NEWCUST_KEY"] = '001';
      requestData.sendData["SPST_INQRY_TYP_CD"] = SPST_INQRY_TYP_CD;
      requestData.sendData["USE_YN"] = "Y"

      let response = await this.common_postCall_noPB(requestData);
      this.getSpstInqryTypLevel2Callback(response);
    },

    getSpstInqryTypLevel2Callback(response) {
      this.spstInqryTypItems3.push(...response.DATA);
    },

    //attr가져오기
    async getAttrList() {
      let aspNewcustKey = '001';
      //회사구분 변경시 소속 콤보박스에 selected 된것 초기화

      this.dropA = await this.mixin_get_attr_by_attr('****', '');
      this.dropB = [{CD: '', CD_NM: '전체'}];
      this.CNSL_COMBO = [{USER_ID: '', USER_NM: '전체'}];
      //this.dropB = await this.mixin_attr_get(aspNewcustKey, "B","전체");
      //this.dropC = await this.mixin_attr_get(aspNewcustKey, "C","전체");
      //this.dropD = await this.mixin_attr_get(aspNewcustKey, "D","전체");

      this.USER_ATTR_A = "";
      this.USER_ATTR_B = "";
      this.USER_ATTR_C = "";
      this.USER_ATTR_D = "";
      this.USER_ID = "";
      },

    //유저 조회
    searchUser() {
      if(this.ASP_NEWCUST_KEY == null || this.ASP_NEWCUST_KEY == ''){
        this.common_alert(this.msgList.noAspCustKey, "error");
        return
      }

      if (this.SPST_INQRY_TYP_CD1 == '') {
        this.common_alert(this.msgList.requiredInqry, "error");
        return false
      }
      //선택된 로우 초기화
      this.detailData = [];
      this.detailData2 = [];

      //그리드 초기화
      this.gridDataText = [];
      this.gridDataText2 = [];

      this.asgnUser();
      this.unasgnUser();

    },

    async unasgnUser() {
      let requestData = {
        headers : {},
        sendData: {}
      };

      // header 세팅
      requestData.headers["URL"] = "/api/chat/setting/pro-counsel-by-agent-manage/inqry-ty-unasgn-user/inqire";
      requestData.headers["METHOD"] = "inqire";
      requestData.headers["SERVICE"] = 'chat.setting.pro-counsel-by-agent-manage.inqry-ty-unasgn-user';
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["GRID_ID"] = "divGridAuthUserNoAlloc";
      requestData.headers["ROW_CNT"] = 200;
      requestData.headers["PAGES_CNT"] = 1;

      // sendData 세팅
      requestData.sendData["ASP_NEWCUST_KEY"] = this.ASP_NEWCUST_KEY;
      requestData.sendData["TALK_INQRY_CD"] = this.SPST_INQRY_TYP_CD1;
      requestData.sendData["TALK_INQRY_CD2"] = this.SPST_INQRY_TYP_CD2;
      requestData.sendData["TALK_INQRY_CD3"] = this.SPST_INQRY_TYP_CD3;
      requestData.sendData["USER_ID"] = this.USER_ID;
      requestData.sendData["USER_NM"] = this.USER_NM;
      requestData.sendData["USER_ATTR_A"] = this.USER_ATTR_A;
      requestData.sendData["USER_ATTR_B"] = this.USER_ATTR_B;
      requestData.sendData["USER_ATTR_C"] = this.USER_ATTR_C;
      requestData.sendData["USER_ATTR_D"] = this.USER_ATTR_D;
      requestData.sendData["USER_NICK"] = this.USER_NICK;


      let unasgnUserResponse = await this.common_postCall(requestData);
      this.unasgnUserCallback(unasgnUserResponse);
    },
    unasgnUserCallback(response) {
      console.log("response.DATA ", response.DATA)
      console.log("response.DATA ", JSON.stringify(response.DATA))
      console.log("response.DATA ", JSON.stringify(response.DATA[1]))

      this.gridDataText = response.DATA;
      this.TOT_COUNT = response.HEADER.TOT_COUNT;

      let idx = 1;
      let dataText = this.gridDataText;
      for (let i in dataText) {
        let id = this.ASP_NEWCUST_KEY + this.gridDataText[i]["TALK_INQRY_CD"] + this.gridDataText[i]["USER_ID"];
        let USER_NM_ID = this.gridDataText[i]["USER_NM"] + "(" + this.gridDataText[i]["USER_ID"] + ")";
        this.gridDataText[i]["id"] = id;
        this.gridDataText[i]["index"] = idx++;
        this.gridDataText[i]["USER_NM_ID"] = USER_NM_ID;
      }

      //초기화
      this.selectedDivGridNoAllocList = [];

    },

    async asgnUser() {
      let requestData = {
        headers : {},
        sendData: {}
      };

      // header 세팅
      requestData.headers["URL"] = "/api/chat/setting/pro-counsel-by-agent-manage/inqry-ty-asgn-user/inqire";
      requestData.headers["METHOD"] = "inqire";
      requestData.headers["SERVICE"] = 'chat.setting.pro-counsel-by-agent-manage.inqry-ty-asgn-user';
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["GRID_ID"] = "divGridAuthUserAlloc";
      requestData.headers["ROW_CNT"] = 200;
      requestData.headers["PAGES_CNT"] = 1;

      // sendData 세팅
      requestData.sendData["ASP_NEWCUST_KEY"] = this.ASP_NEWCUST_KEY;
      requestData.sendData["TALK_INQRY_CD"] = this.SPST_INQRY_TYP_CD1;
      requestData.sendData["TALK_INQRY_CD2"] = this.SPST_INQRY_TYP_CD2;
      requestData.sendData["TALK_INQRY_CD3"] = this.SPST_INQRY_TYP_CD3;
      requestData.sendData["USER_ID"] = this.USER_ID;
      requestData.sendData["USER_NM"] = this.USER_NM;
      requestData.sendData["USER_ATTR_A"] = this.USER_ATTR_A;
      requestData.sendData["USER_ATTR_B"] = this.USER_ATTR_B;
      requestData.sendData["USER_ATTR_C"] = this.USER_ATTR_C;
      requestData.sendData["USER_ATTR_D"] = this.USER_ATTR_D;
      requestData.sendData["USER_NICK"] = this.USER_NICK;


      let asgnUserResponse = await this.common_postCall(requestData);
      this.asgnUserCallback(asgnUserResponse);
    },
    asgnUserCallback(response) {
      this.gridDataText2 = response.DATA;

      this.TOT_COUNT2 = response.HEADER.TOT_COUNT;

      let idx = 1;
      let dataText = this.gridDataText2;
      for (let i in dataText) {
        let id = this.ASP_NEWCUST_KEY + this.gridDataText2[i]["TALK_INQRY_CD"] + this.gridDataText2[i]["USER_ID"];
        this.gridDataText2[i]["id"] = id;
        this.gridDataText2[i]["index"] = idx++;
      }

      //원본저장
      this.asgnGridData = [];
      for (let i in this.gridDataText2) {
        this.asgnGridData.push(this.gridDataText2[i]);
      }

      //초기화
      this.selectedDivGridAllocList = [];

    },

    //저장컨펌창
    saveBtn(tab) {
      if (tab == 'tab1') {
        if (Object.keys(this.gridDataText).length == 0 && Object.keys(this.gridDataText2).length == 0) {
          this.common_alert(this.msgList.noGridList, "error");
          return
        }
        this.common_confirm(this.msgList.saveMsg, this.saveAsgnUser, tab, null, null, "chk");
      } else if (tab == 'tab2') {
        if (Object.keys(this.gridDataText3).length == 0 && Object.keys(this.gridDataText4).length == 0) {
          this.common_alert(this.msgList.noGridList, "error");
          return
        }
        this.common_confirm(this.msgList.saveMsg, this.saveAsgnUser, tab, null, null, "chk");
      }
    },

    //저장
    async saveAsgnUser(tab) {
      let rstSelectedGrid = [];
      let rstSelectedGrid2 = [];
      let rstSelectedGrid3 = [];
      let rstSelectedGrid4 = [];

      if (tab == 'tab1') {
        //할당에 있던 유저 중 미할당으로 된 유저들
        //this.asgnGridData = 원본
        //this.gridDataText2 = 변경
        for (let i = 0; i < this.asgnGridData.length; i++) {
          if (!JSON.stringify(this.gridDataText2).includes(JSON.stringify(this.asgnGridData[i]))) {
            rstSelectedGrid2.push(this.asgnGridData[i]);
          }
        }
        //미할당에 있던 유저 중 할등으로 된 유저들
        for (let i = 0; i < this.gridDataText2.length; i++) {
          if (!JSON.stringify(this.asgnGridData).includes(JSON.stringify(this.gridDataText2[i]))) {
            rstSelectedGrid.push(this.gridDataText2[i]);
          }
        }

        for (let i = 0; i < 2; i++) {//미할당, 할당 저장 후 재조회
          let submitList = [];

          let requestData = {
            headers : {},
            sendData: {}
          };

          // header 세팅
          requestData.headers["URL"] = "/api/chat/setting/pro-counsel-by-agent-manage/inqry-ty/process";
          requestData.headers["METHOD"] = "process";
          requestData.headers["SERVICE"] = 'chat.setting.pro-counsel-by-agent-manage.inqry-ty';
          requestData.headers["TYPE"] = "BIZ_SERVICE";
          requestData.headers["ASYNC"] = false;

          if (rstSelectedGrid.length > 0) {
            let idx = 0;
            for (let i in rstSelectedGrid) {
              let obj = {
                divGridAuthUserAlloc_CHECKBOX: 0,
                DATA_FLAG                    : "I",
                REG_DEPT_CD                 : "x",
                PROC_ID                      : this.$store.getters['userStore/GE_USER_ROLE'].userId,
                REG_ID                      : this.$store.getters['userStore/GE_USER_ROLE'].userId,
                UPD_DEPT_CD                 : "x",
                UPD_ID                      : this.$store.getters['userStore/GE_USER_ROLE'].userId,
                TALK_INQRY_CD                : this.SPST_INQRY_TYP_CD1,
                TALK_INQRY_CD2               : this.SPST_INQRY_TYP_CD2,
                TALK_INQRY_CD3               : this.SPST_INQRY_TYP_CD3,
                USER_ID                      : rstSelectedGrid[i].USER_ID,
                USER_NM                      : rstSelectedGrid[i].USER_NM,
                USER_NICK                    : rstSelectedGrid[i].USER_NICK,
                ASP_CUST_KEY                 : this.ASP_NEWCUST_KEY,
              };

              if (idx === 0) {
                rstSelectedGrid[i].ATTR_DIV_NM_A == '' ? obj.ATTR_DIV_NM_A = "&#91;!NULL!&#93;" : rstSelectedGrid[i].ATTR_DIV_NM_A;
                rstSelectedGrid[i].ATTR_DIV_NM_B == '' ? obj.ATTR_DIV_NM_B = "&#91;!NULL!&#93;" : rstSelectedGrid[i].ATTR_DIV_NM_B;
                rstSelectedGrid[i].ATTR_DIV_NM_C == '' ? obj.ATTR_DIV_NM_C = "&#91;!NULL!&#93;" : rstSelectedGrid[i].ATTR_DIV_NM_C;
                rstSelectedGrid[i].ATTR_DIV_NM_D == '' ? obj.ATTR_DIV_NM_D = "&#91;!NULL!&#93;" : rstSelectedGrid[i].ATTR_DIV_NM_D;
              } else {
                obj.ATTR_DIV_NM_A = rstSelectedGrid[i].ATTR_DIV_NM_A;
                obj.ATTR_DIV_NM_B = rstSelectedGrid[i].ATTR_DIV_NM_B;
                obj.ATTR_DIV_NM_C = rstSelectedGrid[i].ATTR_DIV_NM_C;
                obj.ATTR_DIV_NM_D = rstSelectedGrid[i].ATTR_DIV_NM_D;
              }
              submitList.push(obj);
              idx++;
            }
            // sendData 세팅
            requestData.sendData["PROC_TYPE"] = 'INQRY';
            requestData.sendData["seletedList"] = submitList;

            rstSelectedGrid = [];


          } else if (rstSelectedGrid2.length > 0) {

            let idx = 0;
            for (let i in rstSelectedGrid2) {
              let obj = {
                divGridAuthUserAlloc_CHECKBOX: 1,
                DATA_FLAG                    : "D",
                REG_DEPT_CD                 : "x",
                PROC_ID                      : this.$store.getters['userStore/GE_USER_ROLE'].userId,
                REG_ID                      : this.$store.getters['userStore/GE_USER_ROLE'].userId,
                UPD_DEPT_CD                 : "x",
                UPD_ID                      : this.$store.getters['userStore/GE_USER_ROLE'].userId,
                TALK_INQRY_CD                : this.SPST_INQRY_TYP_CD1,
                TALK_INQRY_CD2               : this.SPST_INQRY_TYP_CD2,
                TALK_INQRY_CD3               : this.SPST_INQRY_TYP_CD3,
                USER_ID                      : rstSelectedGrid2[i].USER_ID,
                USER_NM                      : rstSelectedGrid2[i].USER_NM,
                USER_NICK                    : rstSelectedGrid2[i].USER_NICK,
                ASP_CUST_KEY                 : this.ASP_NEWCUST_KEY,
              };

              if (idx === 0) {
                rstSelectedGrid2[i].ATTR_DIV_NM_A == '' ? obj.ATTR_DIV_NM_A = "&#91;!NULL!&#93;" : rstSelectedGrid2[i].ATTR_DIV_NM_A;
                rstSelectedGrid2[i].ATTR_DIV_NM_B == '' ? obj.ATTR_DIV_NM_B = "&#91;!NULL!&#93;" : rstSelectedGrid2[i].ATTR_DIV_NM_B;
                rstSelectedGrid2[i].ATTR_DIV_NM_C == '' ? obj.ATTR_DIV_NM_C = "&#91;!NULL!&#93;" : rstSelectedGrid2[i].ATTR_DIV_NM_C;
                rstSelectedGrid2[i].ATTR_DIV_NM_D == '' ? obj.ATTR_DIV_NM_D = "&#91;!NULL!&#93;" : rstSelectedGrid2[i].ATTR_DIV_NM_D;
              } else {
                obj.ATTR_DIV_NM_A = rstSelectedGrid2[i].ATTR_DIV_NM_A;
                obj.ATTR_DIV_NM_B = rstSelectedGrid2[i].ATTR_DIV_NM_B;
                obj.ATTR_DIV_NM_C = rstSelectedGrid2[i].ATTR_DIV_NM_C;
                obj.ATTR_DIV_NM_D = rstSelectedGrid2[i].ATTR_DIV_NM_D;
              }

              //전체 미할당처리
              if (Object.keys(rstSelectedGrid2).length < 1) {
                obj.DATA_FLAG = "DEL_ALL";
              }
              submitList.push(obj);
              idx++;
            }
            // sendData 세팅
            requestData.sendData["PROC_TYPE"] = 'INQRY';
            requestData.sendData["seletedList"] = submitList;
            rstSelectedGrid2 = [];
          }
          let response = await this.common_postCall(requestData);

          //재조회
          if (i == 1) {
            this.saveAsgnUserCallback(response, tab);
          }
        }
      } else if (tab == 'tab2') {
        //할당에 있던 유저 중 미할당으로 된 유저들
        //this.asgnGridData = 원본
        //this.gridDataText2 = 변경
        for (let i = 0; i < this.asgnGridData2.length; i++) {
          if (!JSON.stringify(this.gridDataText4).includes(JSON.stringify(this.asgnGridData2[i]))) {
            rstSelectedGrid4.push(this.asgnGridData2[i]);
          }
        }
        //미할당에 있던 유저 중 할등으로 된 유저들
        for (let i = 0; i < this.gridDataText4.length; i++) {
          if (!JSON.stringify(this.asgnGridData2).includes(JSON.stringify(this.gridDataText4[i]))) {
            rstSelectedGrid3.push(this.gridDataText4[i]);
          }
        }
      }

      for (let i = 0; i < 2; i++) {//미할당, 할당 저장 후 재조회
        let submitList = [];

        let requestData = {
          headers : {},
          sendData: {}
        };

        // header 세팅
        requestData.headers["URL"] = "/api/chat/setting/pro-counsel-by-agent-manage/inqry-ty/process";
        requestData.headers["METHOD"] = "process";
        requestData.headers["SERVICE"] = 'chat.setting.pro-counsel-by-agent-manage.inqry-ty';
        requestData.headers["TYPE"] = "BIZ_SERVICE";
        requestData.headers["ASYNC"] = false;

        if (rstSelectedGrid3.length > 0) {
          //||SKILL_RANK||TALK_INQRY_CD||USER_ID||DATA_FLAG||PROC_ID||REG_DEPT_CD||REG_ID||PROC_TYPE
          for (let i in rstSelectedGrid3) {
            let obj = {
              divGridAuthGroupAlloc_CHECKBOX: 0,
              INQRY_TYP_NM                  : rstSelectedGrid3[i].INQRY_TYP_NM,
              INQRY_TYPE                    : rstSelectedGrid3[i].INQRY_TYPE,
              INQRY_USE_CHANNEL             : rstSelectedGrid3[i].INQRY_USE_CHANNEL,
              SKILL_RANK                    : rstSelectedGrid3[i].SKILL_RANK,
              TALK_INQRY_CD                 : rstSelectedGrid3[i].TALK_INQRY_CD,
              USER_ID                       : rstSelectedGrid3[i].USER_ID,
              DATA_FLAG                     : "I",
              PROC_ID                       : this.$store.getters['userStore/GE_USER_ROLE'].userId,
              REG_DEPT_CD                  : "x",
              REG_ID                       : this.$store.getters['userStore/GE_USER_ROLE'].userId,
              UPD_DEPT_CD                 : "x",
              UPD_ID                      : this.$store.getters['userStore/GE_USER_ROLE'].userId,
              ASP_CUST_KEY                  : this.ASP_NEWCUST_KEY_2,
            };

            submitList.push(obj);
          }
          // sendData 세팅
          requestData.sendData["PROC_TYPE"] = 'USER';
          requestData.sendData["seletedList"] = submitList;
          rstSelectedGrid3 = [];

        } else if (rstSelectedGrid4.length > 0) {
          let idx = 0;
          for (let i in rstSelectedGrid4) {
            let obj = {
              divGridAuthGroupAlloc_CHECKBOX: 1,
              INQRY_TYP_NM                  : rstSelectedGrid4[i].INQRY_TYP_NM,
              INQRY_TYPE                    : rstSelectedGrid4[i].INQRY_TYPE,
              INQRY_USE_CHANNEL             : rstSelectedGrid4[i].INQRY_USE_CHANNEL,
              SKILL_RANK                    : rstSelectedGrid4[i].SKILL_RANK,
              TALK_INQRY_CD                 : rstSelectedGrid4[i].TALK_INQRY_CD,
              USER_ID                       : rstSelectedGrid4[i].USER_ID,
              DATA_FLAG                     : "D",
              PROC_ID                       : this.$store.getters['userStore/GE_USER_ROLE'].userId,
              REG_DEPT_CD                  : "x",
              REG_ID                       : this.$store.getters['userStore/GE_USER_ROLE'].userId,
              UPD_DEPT_CD                 : "x",
              UPD_ID                      : this.$store.getters['userStore/GE_USER_ROLE'].userId,
              ASP_CUST_KEY                  : this.ASP_NEWCUST_KEY_2,
            };

            //전체 미할당처리
            if (Object.keys(rstSelectedGrid4).length < 1) {
              obj.DATA_FLAG = "DEL_ALL";
            }
            submitList.push(obj);
          }
          // sendData 세팅
          requestData.sendData["PROC_TYPE"] = 'USER';
          requestData.sendData["seletedList"] = submitList;
          rstSelectedGrid4 = [];
        }
        let response = await this.common_postCall(requestData);

        //재조회
        if (i == 1) {
          this.saveAsgnUserCallback(response, tab);
        }
      }
    },
    saveAsgnUserCallback(response, tab) {
      console.log(response);
      this.common_alert(this.msgList.savedMsg, "done");

      if (tab == 'tab1') {
        this.searchUser();
      } else if (tab == 'tab2') {
        this.searchSkillBtn();
      }

    },

    //-----------------------------------------------------------------------------------------------------

    /*
    * 상담사별 스킬설정
    */

    //유저검색팝업
    btnUser() {
      let user = {
        ASP_CUST_KEY: this.ASP_NEWCUST_KEY_2,
        USER_ID: this.USER_ID_2,
        USER_NM: this.USER_NM_2,
      };
      this.userInfo = user;
    },

    setUser() {
      this.USER_ID_2 = this.userInfo.USER_ID;
      this.USER_NM_2 = this.userInfo.USER_NM;
      this.USER_NICK_2 = this.userInfo.USER_NICK;
    },


    //초기화 버튼
    initCus() {
      this.USER_ID_2 = "";
      this.USER_NM_2 = "";
      this.USER_NICK_2 = "";
    },

    //상담사별 스킬설정 조회
    searchSkillBtn() {
      if(this.ASP_NEWCUST_KEY_2 == null || this.ASP_NEWCUST_KEY_2 == ''){
        this.common_alert(this.msgList.noAspCustKey, "error");
        return
      }
      if(this.mixin_isEmpty(this.USER_ID_2)){
        this.common_alert(this.msgList.requiredUser, "error");
        return
      }

      //선택된 로우 초기화
      this.detailData = [];
      this.detailData2 = [];

      this.searchUnasgnSkill(); //미할당 스킬 조회
      this.searchAsgnSkill(); //할당 스킬 조회
    },

    //미할당 스킬 조회
    async searchUnasgnSkill() {
      let requestData = {
        headers : {},
        sendData: {}
      };

      // header 세팅
      requestData.headers["URL"] = "/api/chat/setting/pro-counsel-by-agent-manage/unasgn-inqry-ty/inqire";
      requestData.headers["METHOD"] = "inqire";
      requestData.headers["SERVICE"] = 'chat.setting.pro-counsel-by-agent-manage.unasgn-inqry-ty';
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["GRID_ID"] = "divGridAuthGroupNoAlloc";

      // sendData 세팅
      requestData.sendData["ASP_NEWCUST_KEY_2"] = this.ASP_NEWCUST_KEY_2;
      requestData.sendData["USER_ID"] = this.USER_ID_2;
      requestData.sendData["USER_NM"] = this.USER_NM_2;
      requestData.sendData["USER_NICK"] = this.USER_NICK_2;
      requestData.sendData["NULLABLE"] = "IS NULL";
      requestData.sendData["USER_ATTR_A"] = "";			//상담원명
      requestData.sendData["USER_ATTR_B"] = "";			//상담원명
      requestData.sendData["USER_ATTR_C"] = "";			//상담원명
      requestData.sendData["USER_ATTR_D"] = "";			//상담원명
      requestData.sendData["ROW_CNT"] = 200;				//페이징_ROW_CNT
      requestData.sendData["PAGES_CNT"] = 1;				//페이징_PAGES_CNT

      let response = await this.common_postCall(requestData);
      this.searchUnasgnSkillCallback(response);
    },
    searchUnasgnSkillCallback(response) {
      this.gridDataText3 = response.DATA;
      //this.TOT_COUNT3 = response.HEADER.TOT_COUNT;
      this.TOT_COUNT3 = response.HEADER.TOT_COUNT;

      let idx = 1;
      let dataText = this.gridDataText3;
      for (let i in dataText) {
        let id = this.ASP_NEWCUST_KEY_2 + this.gridDataText3[i]["TALK_INQRY_CD"] + this.gridDataText3[i]["USER_ID"];
        this.gridDataText3[i]["id"] = id;
        this.gridDataText3[i]["index"] = idx++;
      }

      //초기화
      this.seletedAuthGroupNoAlloc = [];


    },
    //할당 스킬 조회
    async searchAsgnSkill() {
      let requestData = {
        headers : {},
        sendData: {}
      };

      // header 세팅
      requestData.headers["URL"] = "/api/chat/setting/pro-counsel-by-agent-manage/unasgn-inqry-ty/inqire";
      requestData.headers["METHOD"] = "inqire";
      requestData.headers["SERVICE"] = 'chat.setting.pro-counsel-by-agent-manage.unasgn-inqry-ty';
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["GRID_ID"] = "divGridAuthGroupAlloc";

      // sendData 세팅
      requestData.sendData["ASP_NEWCUST_KEY_2"] = this.ASP_NEWCUST_KEY_2;
      requestData.sendData["USER_ID"] = this.USER_ID_2;
      requestData.sendData["USER_NM"] = this.USER_NM_2;
      requestData.sendData["USER_NICK"] = this.USER_NICK_2;
      requestData.sendData["NULLABLE"] = "IS NOT NULL";
      requestData.sendData["USER_ATTR_A"] = "";			//상담원명
      requestData.sendData["USER_ATTR_B"] = "";			//상담원명
      requestData.sendData["USER_ATTR_C"] = "";			//상담원명
      requestData.sendData["USER_ATTR_D"] = "";			//상담원명
      requestData.sendData["ROW_CNT"] = 200;				//페이징_ROW_CNT
      requestData.sendData["PAGES_CNT"] = 1;				//페이징_PAGES_CNT

      let response = await this.common_postCall(requestData);
      this.searchAsgnSkillCallback(response);
    },
    searchAsgnSkillCallback(response) {
      this.gridDataText4 = response.DATA;
      this.TOT_COUNT4 = response.HEADER.TOT_COUNT;

      let idx = 1;
      let dataText = this.gridDataText4;
      for (let i in dataText) {
        let id = this.ASP_NEWCUST_KEY_2 + this.gridDataText4[i]["TALK_INQRY_CD"] + this.gridDataText4[i]["USER_ID"];
        this.gridDataText4[i]["id"] = id;
        this.gridDataText4[i]["index"] = idx++;
      }
      //원본저장
      this.asgnGridData2 = [];
      for (let i in this.gridDataText4) {
        this.asgnGridData2.push(this.gridDataText4[i]);
      }

      //초기화
      this.seletedAuthGroupAlloc = [];
    },

    //스킬순서 변경버튼
    modifyBtn(FLAG) {
      if (this.seletedAuthGroupAlloc.length < 1) {
        this.common_alert(this.msgList.skillNotSeleted, "error")
        return
      }
      this.common_confirm(this.msgList.chkUpDown[FLAG], this.modifySkillLevel, FLAG, null, null, "chk")
    },

    //스킬순서 변경
    async modifySkillLevel(FLAG) {
      let submitList = [];
      let strBoolean = false;
      let requestData = {
        headers : {},
        sendData: {}
      };

      for (let i in this.seletedAuthGroupAlloc) {
        if (this.seletedAuthGroupAlloc[i].SKILL_RANK == "1") {
          strBoolean = true;
        }
      }

      if (strBoolean) {
        if (FLAG == "up") {
          this.common_alert(this.msgList.cantUp, "error");
          return;
        }
      }

      // 유저의 등록된 CUST_KEY가 다 들어갈 경우
      // async getCustList(){
      //   let companyList = await this.common_getAspDataS("kuterplayer");
      //   let cmpObj = [];
      //
      //   for(let i in companyList.kuterplayer){
      //     cmpObj.push(companyList.kuterplayer[i].CD);
      //   }
      //   this.resString = cmpObj.toString();
      //   console.log(this.resString);
      // },

      //submitList셋팅
      for (let i in this.seletedAuthGroupAlloc) {
        let obj = {
          divGridAuthGroupAlloc_CHECKBOX: 1,
          INQRY_TYP_NM                  : this.seletedAuthGroupAlloc[i].INQRY_TYP_NM,
          INQRY_TYPE                    : this.seletedAuthGroupAlloc[i].INQRY_TYPE,
          INQRY_USE_CHANNEL             : 2,
          TALK_INQRY_CD                 : this.seletedAuthGroupAlloc[i].TALK_INQRY_CD,
          SKILL_RANK                    : this.seletedAuthGroupAlloc[i].SKILL_RANK,
          USER_ID                       : this.seletedAuthGroupAlloc[i].USER_ID,
          DATA_FLAG                     : 'N',
        };
        submitList.push(obj);
      }

      // sendData 세팅
      requestData.sendData["FLAG"] = FLAG;
      requestData.sendData["ASP_NEWCUST_KEY"] = this.ASP_NEWCUST_KEY_2;
      requestData.sendData["ASP_CUST_KEY"] = this.ASP_NEWCUST_KEY_2;
      // requestData.sendData["ASP_NEWCUST_KEY"] = this.resString;
      // requestData.sendData["ASP_CUST_KEY"] = this.resString;
      requestData.sendData["seletedList"] = submitList;


      // header 세팅
      requestData.headers["URL"] = "/api/chat/setting/pro-counsel-by-agent-manage/skill-priort/modify";
      requestData.headers["METHOD"] = "modify";
      requestData.headers["SERVICE"] = 'chat.setting.pro-counsel-by-agent-manage.skill-priort';
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;

      let response = await this.common_postCall_noPB(requestData);
      this.modifySkillLevelCallback(response);
    },
    modifySkillLevelCallback(response) {
      console.log(response)
      this.searchSkillBtn();
      this.seletedAuthGroupAlloc = [];
    },

    //오른쪽 이동
    moveRight(tab) {
      let grid1_selected = this.selectedDivGridNoAllocList;  // 미할당 사용 체크박스
      let grid3_selected = this.seletedAuthGroupNoAlloc;  // 미할당 사용 체크박스
      let grid1 = this.gridDataText;     // 미할당상담사 그리드
      let grid2 = this.gridDataText2;       // 할당상담사 그리드
      let grid3 = this.gridDataText3;     // 미할당상담사 그리드
      let grid4 = this.gridDataText4;       // 할당상담사 그리드

      if (tab == 'tab1') {
        if (grid1_selected.length < 1) {
          this.common_alert(this.msgList.noAlloSelected, "error");
          return
        }
        for (let i in grid1_selected) {
          grid1.splice(grid1.indexOf(grid1_selected[i]), 1);
          grid2.push(grid1_selected[i]);
        }
        this.selectedDivGridNoAllocList = [];

      } else if (tab == 'tab2') {
        if (grid3_selected.length < 1) {
          this.common_alert(this.msgList.noAlloSelected, "error");
          return
        }
        for (let i in grid3_selected) {
          grid3.splice(grid3.indexOf(grid3_selected[i]), 1);
          grid4.push(grid3_selected[i]);
        }
        this.seletedAuthGroupNoAlloc = [];
      }
    },

    //왼쪽이동
    moveLeftBtn(tab) {
      if (tab == 'tab1') {
        if (this.selectedDivGridAllocList.length < 1) {
          this.common_alert(this.msgList.alloSelected, "error");
          return
        }
      } else if (tab == 'tab2') {
        if (this.seletedAuthGroupAlloc.length < 1) {
          this.common_alert(this.msgList.alloSelected, "error");
          return
        }
      }
      this.common_confirm(this.msgList.moveLeft[tab], this.moveLeft, tab, null, null, null);
    },
    moveLeft(tab) {
      let grid2_selected = this.selectedDivGridAllocList;   // 할당 사용 체크박스
      let grid4_selected = this.seletedAuthGroupAlloc;   // 할당 사용 체크박스
      let grid1 = this.gridDataText;     // 미할당상담사 그리드
      let grid2 = this.gridDataText2;       // 할당상담사 그리드
      let grid3 = this.gridDataText3;     // 미할당스킬 그리드
      let grid4 = this.gridDataText4;       // 할당스킬 그리드

      if (tab == 'tab1') {
        if (grid2_selected.length < 1) {
          this.common_alert(this.msgList.alloSelected, "error");
          return
        }

        for (let i in grid2_selected) {
          grid2.splice(grid2.indexOf(grid2_selected[i]), 1);
          grid1.push(grid2_selected[i]);
        }
        this.selectedDivGridAllocList = [];
      } else if (tab == 'tab2') {
        if (grid4_selected.length < 1) {
          this.common_alert(this.msgList.alloSelected, "error");
          return
        }

        for (let i in grid4_selected) {
          grid4.splice(grid4.indexOf(grid4_selected[i]), 1);
          grid3.push(grid4_selected[i]);
        }
        this.seletedAuthGroupAlloc = [];
      }
    },

  },
  mounted() {
    //this.getCustList();
    this.custItems = this.$store.getters['userStore/GE_USER_COMPANY'];
    this.SPST_INQRY_TYP_CD2 = '';
    this.SPST_INQRY_TYP_CD3 = '';
      this.getAttrList();
      this.getSpstInqryTypList();

  },
  computed: {

  },
};
</script>

<style></style>
